import React, { useEffect, useState } from "react";
import styled from "styled-components";
import backgroundImg from "../assets/images/footer-bg.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import LogoLight from "../assets/images/logo/sec_light.png";
import LogoDark from "../assets/images/logo/sec_dark.png";
// import cyboidlogo from "../assets/images/Cyboid logo trs.png";
// import ScrollToSection from "../hooks/ScrollToSection";
// import { useLocation, useNavigate } from "react-router-dom";

const FooterContainer = styled.footer`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  position: relative;
  color: #ffffff;
  padding: 60px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  ${"" /* margin-top: 40px; */}
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 0, 50, 0.85);
    z-index: 1;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 20px;
  min-width: 200px;
  max-width: 300px;
  position: relative;
`;

const SectionTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
  color: #e0e0e0;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    height: 2px;
    width: 25px;
    background: #6233ff;
  }
`;

const FooterLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  display: block;
  margin: 8px 0;
  font-size: 1rem;
  &:hover {
    color: #6233ff;
  }
`;

const NewsletterInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 15px;
  width: 80%;
  font-size: 1rem;
`;

const NewsletterButton = styled.button`
  background-color: #6233ff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
`;

const SocialMediaIcons = styled.div`
  margin-top: 20px;
  /* margin-left: 5px; */
  display: flex;
  justify-content: center;
  a {
    color: #ffffff;
    margin: 0 10px;
    font-size: 1.5rem;
    &:hover {
      color: #6233ff;
    }
  }
`;

const Footer = () => {
    // const [scrollSection, setScrollSection] = useState(null);
    // const navigate = useNavigate();
    // const location = useLocation();

    // const handleScrollToSection = (e, sectionId) => {
    //     e.preventDefault();

    //     if (location.pathname !== "/") {
    //         navigate("/", { replace: true });
    //         setScrollSection(sectionId);
    //     } else {
    //         const section = document.getElementById(sectionId);
    //         if (section) {
    //             section.scrollIntoView({ behavior: "smooth" });
    //         }
    //     }
    // };

    // const handleNavigation = (e, path) => {
    //     e.preventDefault();
    //     navigate(path);
    // };

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }, []);

    return (
        <FooterContainer>
            <FooterSection>
                <SectionTitle
                    style={{ width: "150px;", height: "40px;", marginLeft: "50px" }}
                >
                    {/* <img src={cyboidlogo} alt="Cyboid Technologies Logo" /> */}
                    <img src={LogoLight} alt="SecGuard Logo" className="h-12 w-auto" />
                </SectionTitle>
                <p>
                    We work with a passion of taking challenges and creating new ones in
                    advertising sector.
                </p>
                <SocialMediaIcons>
                    <FooterLink href="#">
                        <i className="fab fa-twitter"></i>
                    </FooterLink>
                    <FooterLink href="#">
                        <i className="fab fa-facebook"></i>
                    </FooterLink>
                    <FooterLink href="#">
                        <i className="fab fa-pinterest"></i>
                    </FooterLink>
                    <FooterLink href="#">
                        <i className="fab fa-instagram"></i>
                    </FooterLink>
                </SocialMediaIcons>
            </FooterSection>

            <FooterSection>
                <SectionTitle>Links</SectionTitle>
                {/* <FooterLink
                    href="#"
                    onClick={(e) => handleScrollToSection(e, "about-us")}
                >
                    About us
                </FooterLink>
                <FooterLink href="#" onClick={(e) => handleNavigation(e, "/products")}>
                    Products
                </FooterLink>
                <FooterLink href="#" onClick={(e) => handleNavigation(e, "/contact")}>
                    Contact us
                </FooterLink> */}
                <FooterLink
                    href="/secguard"
                >
                    SecGuard
                </FooterLink>
                <FooterLink href="/product" >
                    Products
                </FooterLink>
                <FooterLink href="/contact" >
                    Contact us
                </FooterLink>
            </FooterSection>
            {/* <FooterSection>
                <SectionTitle>Newsletter</SectionTitle>
                <p>
                    Signup for our latest news & articles. We won’t give you spam mails.
                </p>
                <NewsletterInput type="email" placeholder="Email Address" />
                <NewsletterButton>Subscribe</NewsletterButton>
            </FooterSection> */}
            <FooterSection>
                <SectionTitle>Contact</SectionTitle>
                <p>
                    <i className="fas fa-phone"></i> &nbsp;&nbsp; +91 9043413318
                </p>

                <p>
                    <i className="fas fa-phone"></i> &nbsp;&nbsp; +91 8939056056
                </p>
                <p>
                    <i className="fas fa-envelope"></i> &nbsp;&nbsp; sales@secguard.in
                </p>
                <p>
                    <i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp; 6/13 North
                    Avenue, Kesavaperumalpuram, <br />
                    Raja Annamalai Piram, Chennai, TamilNadu - 600028
                </p>
            </FooterSection>
            {/* {scrollSection && <ScrollToSection sectionId={scrollSection} />} */}
        </FooterContainer>
    );
};

export default Footer;