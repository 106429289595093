import React from 'react';
import ProductCard from './ProductCard';
import ProductBg from '../assets/images/Product-bg.jpg';
import ForeBg from '../assets/images/sgp.png';
import './ProductSection.css';
import { faNetworkWired, faScaleBalanced, faVault, faGlobe, faMagnifyingGlass, faUsersRays, faHouseLaptop, faFileLines } from '@fortawesome/free-solid-svg-icons';


const ProductSection = () => {
    return (
        <div className="py-16 bg-cover bg-center" style={{ backgroundImage: `url(../assets/images/bg.png)` }}>
            <div className="container mx-auto px-4 md:px-16 py-8">

                <div className="text-center mb-16">
                    <span className="text-3xl font-bold">Our Features</span>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

                    {/* Left Section */}
                    <div>
                        <ProductCard
                            iconSrc={faNetworkWired}
                            title="High-Speed Intrusion Prevention System"
                        />
                        <ProductCard
                            iconSrc={faScaleBalanced}
                            title="Robust VPN support"
                        />
                        <ProductCard
                            iconSrc={faVault}
                            title="Web and Application access control"
                        />
                        <ProductCard
                            iconSrc={faGlobe}
                            title="Precise Routing Functions"
                        />
                    </div>

                    {/* Image Section */}
                    <div className="relative text-center">
                        <img
                            src={ProductBg}
                            alt="Fly in the Sky"
                            className="inline-block"
                        />
                        <img
                            src={ForeBg}
                            alt="Animated Drone"
                            className="absolute animate-circle"
                            style={{ width: '200px', height: '200px' }}
                        />
                    </div>

                    {/* Right Section */}
                    <div>
                        <ProductCard
                            iconSrc={faMagnifyingGlass}
                            title="Policy based Web Filtering"
                        />
                        <ProductCard
                            iconSrc={faUsersRays}
                            title="Protection against advanced zero-day attack campaigns"
                        />
                        <ProductCard
                            iconSrc={faFileLines}
                            title="On-appliance report log"
                        />
                        <ProductCard
                            iconSrc={faHouseLaptop}
                            title="Comprehensive SSL inspection"
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ProductSection;
