import React, { useEffect } from 'react';
import "./FeatureCard.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const FeatureCard = ({ icon, title, description, className }) => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className={`flex items-center bg-blue-500 ${className} text-white p-4 lg hover-bg transition duration-300`} data-aos="zoom-in-up">

            <div className="text-4xl mr-6 rotate-icon">
                {icon}
            </div>
            <div className="flex flex-col">
                <h3 className="text-1xl font-bold mb-2">{title}</h3>
                <p className="text-md leading-relaxed">
                    {description}
                </p>
            </div>
        </div>
    );
};

export default FeatureCard;
