import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaPhoneAlt } from 'react-icons/fa';
import LogoLight from "../assets/images/logo/sec_light.png";
import LogoDark from "../assets/images/logo/sec_dark.png";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 50) {  // Adjust the scroll position as per your need
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white text-black shadow-lg' : 'bg-black text-white'}`}>
            <div className="container mx-auto flex justify-between items-center py-4 px-6">
                {/* Brand Logo */}
                <div className="flex items-center">
                    <Link to="/">
                        <img src={isScrolled ? LogoDark : LogoLight} alt="SecGuard Logo" className="h-12 w-auto" />
                    </Link>
                </div>

                {/* Desktop Menu - Moved to the Left */}
                <div className="flex-grow flex items-center ml-10">
                    <ul className="hidden md:flex space-x-8 text-lg font-medium">
                        <li><Link to="/" className={`hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>Home</Link></li>
                        <li><Link to="/secguard" className={`hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>SecGuard</Link></li>
                        <li><Link to="/product" className={`hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>Products</Link></li>
                        {/* <li className="relative group">
                            <button className={`flex items-center space-x-2 hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>
                                <span>Products</span>
                                <svg className="w-4 h-4 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>
                            <ul className="absolute left-0 mt-2 w-48 bg-white text-black shadow-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <li>
                                    <Link to="/Product1" className="block px-4 py-2 hover:bg-gray-100">Product 1</Link>
                                </li>
                                <li>
                                    <Link to="/Product2" className="block px-4 py-2 hover:bg-gray-100">Product 2</Link>
                                </li>
                                <li>
                                    <Link to="/Product3" className="block px-4 py-2 hover:bg-gray-100">Product 3</Link>
                                </li>
                            </ul>
                        </li> */}
                        <li><Link to="/contact" className={`hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>Contact</Link></li>
                    </ul>
                </div>

                {/* Contact Button */}
                <div className="hidden md:flex items-center">
                    <a href="tel:+91-8939056056" className="animate-pulse flex items-center bg-green-500 shadow-lg shadow-green-600/50 text-white py-2 px-4 rounded-full hover:bg-green-600 transition duration-300 ease-in-out">
                        <FaPhoneAlt className="mr-2" /> +91-9043413318
                    </a>
                </div>

                {/* Mobile Menu Button */}
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMenu} className={`text-3xl focus:outline-none ${isScrolled ? 'text-black' : 'text-white'}`}>
                        {isOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className={`${isOpen ? 'block' : 'hidden'} md:hidden ${isScrolled ? 'bg-white text-black' : 'bg-black text-white'}`}>
                <ul className="flex flex-col space-y-4 p-4">
                    <li><Link to="/" onClick={toggleMenu} className="block text-lg hover:text-blue-500 transition-colors duration-300">Home</Link></li>
                    <li><Link to="/secguard" onClick={toggleMenu} className="block text-lg hover:text-blue-500 transition-colors duration-300">SecGuard</Link></li>
                    <li><Link to="/product" className={`hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>Products</Link></li>
                    {/* <li>
                        <button className={`flex items-center space-x-2 hover:${isScrolled ? 'text-blue-500' : 'text-blue-300'} transition-colors duration-300`}>
                            <span>Products</span>
                            <svg className="w-4 h-4 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        <ul className={`${isOpen ? 'block' : 'hidden'} space-y-2 p-2`}>
                            <li>
                                <Link to="/Product1" className="block px-4 py-2 hover:bg-gray-100">Product 1</Link>
                            </li>
                            <li>
                                <Link to="/Product2" className="block px-4 py-2 hover:bg-gray-100">Product 2</Link>
                            </li>
                            <li>
                                <Link to="/Product3" className="block px-4 py-2 hover:bg-gray-100">Product 3</Link>
                            </li>
                        </ul>
                    </li> */}
                    <li><Link to="/contact" onClick={toggleMenu} className="block text-lg hover:text-blue-500 transition-colors duration-300">Contact</Link></li>
                    {/* <li>
                        <a href="tel:+91-8939056056" className="block text-lg hover:text-blue-500 transition-colors duration-300">
                            <FaPhoneAlt className="mr-2" /> +91-9043413318
                        </a>
                    </li> */}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
