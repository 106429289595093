import Airtable from "airtable";

const base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

export const createRecord = async (data) => {
    try {
        const records = await base("Contact_Form").create([
            {
                fields: {
                    Name: data.name,
                    Email: data.email,
                    Contact: data.phone,
                    Subject: data.subject,
                    Message: data.message,
                },
            },
        ]);

        return records;
    } catch (error) {
        throw error;
    }
};
