import React, { useState, useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import HeroBg from '../../assets/images/SecGuard/section1-bg.jpg';
import videoSrc from "../../assets/videos/video.mp4";

const FirstSection = () => {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isTextVisible, setIsTextVisible] = useState(true);
    const videoRef = useRef(null);
    const overlayRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            // Attempt to play video automatically on page load
            videoRef.current.play().catch(() => {
                // Handle auto-play failure (e.g., due to browser restrictions)
                console.log('Auto-play was prevented.');
            });
        }
    }, []);

    const handleWatchVideoClick = () => {
        setIsTextVisible(false);
        setIsVideoPlaying(true);
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.controls = true;
            videoRef.current.muted = false;
            videoRef.current.play();
            overlayRef.current.style.opacity = '0.5';
        }
    };

    const handleCloseVideoClick = () => {
        setIsTextVisible(true); // Show the text again
        setIsVideoPlaying(false); // Pause the video
        if (videoRef.current) {
            videoRef.current.muted = true; // Mute the video
            videoRef.current.play();
            overlayRef.current.style.opacity = '0.7';
        }
    };

    return (
        <div className="relative w-full h-screen overflow-hidden bg-black text-white">
            {/* Background Video */}
            <video
                controls
                ref={videoRef}
                src={videoSrc}
                autoPlay
                muted
                loop
                className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 z-10"
            />

            {/* Overlay for darkening the video */}
            <div ref={overlayRef} className="absolute inset-0 bg-black opacity-70 z-20"></div>

            {/* Content (Text and Watch Button) */}
            <div className={`relative z-30 flex flex-col items-center justify-center w-full h-full px-6 text-center ${!isTextVisible ? 'hidden' : ''}`}>
                <h1 className="text-4xl font-bold mb-4">Empower Your Organization with Cutting-Edge Firewall!</h1>
                <p className="text-lg mb-6">Stop modern-day cyber threats with SecGuard Next Generation Firewall!</p>
                <button
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-600 transition duration-300 ease-in-out"
                    onClick={handleWatchVideoClick}
                >
                    Watch Video
                </button>
            </div>

            {/* Close Button to stop the video and bring the text back */}
            {!isTextVisible && (
                <button
                    className="absolute top-4 right-4 text-white text-3xl z-40 focus:outline-none mt-20 ml-10"
                    onClick={handleCloseVideoClick}
                >
                    <FaTimes />
                </button>
            )}
        </div>
    );
};

export default FirstSection;
