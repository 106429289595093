import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import AOS from "aos";
import "aos/dist/aos.css";
import { createRecord } from "../hooks/createRecord";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  background-color: #f9f9f9;
  flex-wrap: wrap;
  margin-top: 50px;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  margin-right: 50px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

const ContactDetailsWrapper = styled.div`
  flex: 0.5;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  color: #222;
  font-weight: bold;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & > div {
    flex: 1;
    margin-right: 15px;
  }

  & > div:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #080d6e;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  height: 120px;

  &:focus {
    outline: none;
    border-color: #080d6e;
  }
`;

const Button = styled.button`
  background-color: #080d6e;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #161eb8;
  }
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 15px;
`;

const ContactDetail = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  color: #080d6e;
  font-size: 24px;
  margin-right: 10px;
`;

const DetailText = styled.div`
  flex: 1;
`;

const DetailTitle = styled.h4`
  font-size: 18px;
  color: #222;
  font-weight: 600;
  margin-bottom: 5px;
`;

const DetailContent = styled.p`
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
`;

const ContactForm = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);
      // const createdRecord = await createRecord(data);
      // toast.success("Form submitted successfully");
    } catch (error) {
      console.log(error);
      // toast.error("Error in submitting the details");
    } finally {
      reset();
    }
  };

  return (
    <ContactSection>
      {/* <ToastContainer /> */}
      <FormWrapper>
        <Title>Write Us</Title>
        <form onSubmit={handleSubmit(onSubmit)} data-aos="zoom-in">
          <FormRow>
            <div>
              <Input
                type="text"
                placeholder="Name"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && <Error>{errors.name.message}</Error>}
            </div>
            <div>
              <Input
                type="email"
                placeholder="Email Address"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <Error>{errors.email.message}</Error>}
            </div>
          </FormRow>
          <FormRow>
            <div>
              <Input
                type="text"
                placeholder="Phone Number"
                {...register("phone", { required: "Phone number is required" })}
              />
              {errors.phone && <Error>{errors.phone.message}</Error>}
            </div>
            <div>
              <Input
                type="text"
                placeholder="Subject"
                {...register("subject", { required: "Subject is required" })}
              />
              {errors.subject && <Error>{errors.subject.message}</Error>}
            </div>
          </FormRow>
          <div style={{ marginBottom: "20px" }}>
            <TextArea
              placeholder="Message"
              {...register("message", { required: "Message is required" })}
            ></TextArea>
            {errors.message && <Error>{errors.message.message}</Error>}
          </div>
          <Button type="submit">Send Message</Button>
        </form>
      </FormWrapper>

      <ContactDetailsWrapper data-aos="zoom-in">
        <Title>Our Contact Details</Title>

        <ContactDetail>
          <IconWrapper>
            <i className="fas fa-map-marker-alt" />
          </IconWrapper>
          <DetailText>
            <DetailTitle>Address</DetailTitle>
            <DetailContent>
              6/13 North Avenue, Kesavaperumalpuram, Raja Annamalai Piram,
              Chennai, TamilNadu - 600028
            </DetailContent>
          </DetailText>
        </ContactDetail>

        <ContactDetail>
          <IconWrapper>
            <i className="fas fa-phone" />
          </IconWrapper>
          <DetailText>
            <DetailTitle>Phone</DetailTitle>
            <DetailContent> +91 9043413318</DetailContent>
            <DetailContent>+91 8939056056</DetailContent>
          </DetailText>
        </ContactDetail>

        <ContactDetail>
          <IconWrapper>
            <i className="fas fa-envelope" />
          </IconWrapper>
          <DetailText>
            <DetailTitle>Email</DetailTitle>
            <DetailContent>sales@secguard.in</DetailContent>
          </DetailText>
        </ContactDetail>

        <DetailTitle>Follow Us:</DetailTitle>
        <div>
          <IconWrapper className="fab fa-facebook" />
          <IconWrapper className="fab fa-twitter" />
          <IconWrapper className="fab fa-instagram" />
          <IconWrapper className="fab fa-linkedin" />
        </div>
      </ContactDetailsWrapper>
    </ContactSection>
  );
};

export default ContactForm;
