import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionComponent = ({ title, description, imageSrc, reverse, children }) => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className={`flex flex-col md:flex-row items-center justify-between py-12 ${reverse ? 'md:flex-row-reverse' : ''}`}>
            {/* Image Section */}
            <div className="md:w-1/2 mb-8 md:mb-0 flex justify-center" data-aos={`${reverse ? 'fade-left' : 'fade-right'}`}>
                <img
                    src={imageSrc}
                    alt={title}
                    className="w-full h-auto max-w-md rounded-lg shadow-lg"
                />
            </div>

            {/* Text Section */}
            <div className="md:w-1/2 text-center md:text-left" data-aos={`${reverse ? 'fade-right' : 'fade-left'}`}>
                <h2 className="text-3xl font-bold mb-4 text-gray-800">{title}</h2>
                <p className="text-lg text-gray-600 mb-6">
                    {description}
                </p>
                {children && <div className="text-gray-600">{children}</div>}
            </div>
        </div>
    );
};

export default SectionComponent;
