import React, { useEffect } from 'react';
import InfoBg from "../assets/images/Info-bg.png";
import ForeBg from "../assets/images/all.png";
import AOS from 'aos';
import 'aos/dist/aos.css';


const InfoSection = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="py-12 md:py-20 relative bg-no-repeat bg-bottom bg-cover"
            style={{ backgroundImage: `url(${InfoBg})` }}
        >
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-end">
                    <div className="text-white" data-aos="fade-right">
                        <h2 className="text-3xl font-bold">Why choose SecGuard?</h2>
                        <p className="mt-6">
                            SecGuard is a great network security solution for businesses of all sizes that want to protect their networks from cyber threats. It is a good choice for organizations that must meet compliance requirements. Small businesses and Home users also take advantage of enterprise-grade security.
                        </p>
                        {/* Info Items */}
                        {/* <div className="flex space-x-8 mt-10">
               
                            <div className="flex items-center">
                                <img src="/assets/images/icon-5.png" alt="Weight" className="w-12 h-12" />
                                <div className="ml-4">
                                    <p className="text-lg font-bold mb-1"><strong>500</strong> G</p>
                                    <p className="text-blue-500 text-sm uppercase">Weight</p>
                                </div>
                            </div>
                   
                            <div className="flex items-center">
                                <img src="/assets/images/icon-6.png" alt="Frame Rate" className="w-12 h-12" />
                                <div className="ml-4">
                                    <p className="text-lg font-bold mb-1"><strong>60</strong> FPS</p>
                                    <p className="text-blue-500 text-sm uppercase">Frame Rate</p>
                                </div>
                            </div>
  
                            <div className="flex items-center">
                                <img src="/assets/images/icon-7.png" alt="Diameter" className="w-12 h-12" />
                                <div className="ml-4">
                                    <p className="text-lg font-bold mb-1"><strong>40</strong> mm</p>
                                    <p className="text-blue-500 text-sm uppercase">Diameter</p>
                                </div>
                            </div>
                        </div>  */}
                    </div>

                    {/* Right side with the image */}
                    {/* <div className="block-img">
                        <img src={ForeBg} alt="Integrated Vinova Camera" className="max-w-md" />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default InfoSection;
