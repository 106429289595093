import React from "react";
import PageTitleArea from "./PageTitleArea";
import ProductPage from "./ProductPage";

const Services = () => {
    return (
        <>
            <PageTitleArea title="Our Products " breadcrumb="Our Products" />
            <ProductPage />
        </>
    );
};

export default Services;