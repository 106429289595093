import React from 'react';
import FeatureCard from './FeatureCard';
import { FaShieldAlt, FaUserCog, FaRunning } from 'react-icons/fa';


const FeatureSection = () => {
    // Content for each feature card
    const features = [
        {
            icon: <FaShieldAlt />,
            title: 'Comprehensive security',
            description: 'SecGuard can protect against a wide range of threats, including malware, botnets, and phishing attacks.',
        },
        {
            icon: <FaUserCog />,
            title: 'Easy to deploy and manage',
            description: 'SecGuard boasts a straightforward deployment and management process, which is especially advantageous for small businesses.',
        },
        {
            icon: <FaRunning />,
            title: 'Scalable and Cost Effective',
            description: 'SecGuard can be scaled to meet the needs of businesses of all sizes.SecGuard is a cost-effective way to protect your network from cyberattacks.',
        },
    ];

    return (
        <div className="grid bg-blue-500 grid-cols-1 md:grid-cols-3 gap-6 px-4 md:px-16">
            {features.map((feature, index) => (
                <FeatureCard
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                    className={`${index === 1 ? 'bg-blue-700' : 'bg-blue-500'}`}
                />
            ))}
        </div>
    );
};

export default FeatureSection;
