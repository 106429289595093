import Services from "../components/Products/Services";

const Product1 = () => {
    return (
        <>
            <Services />
        </>
    )
}

export default Product1;