import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProductCard = ({ iconSrc, title, description }) => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="flex items-center mb-8" data-aos="zoom-in">

            <div className="text-center mr-6">
                <FontAwesomeIcon icon={iconSrc} size="2x" />
            </div>

            <div>
                <h3 className="text-xl font-bold mb-2">{title}</h3>
                {/* <p className="text-md leading-relaxed">
                    {description}
                </p> */}
            </div>
        </div>
    );
};

export default ProductCard;
