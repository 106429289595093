import React from 'react';
import SectionComponent from './Section3_Component';
import image1 from '../../assets/images/zero_day.jpg';
import image2 from '../../assets/images/content_inspection.jpg';
import image3 from '../../assets/images/TLS.png';
import image4 from '../../assets/images/report.jpg';
import image5 from '../../assets/images/app_control.jpg';
import image6 from '../../assets/images/web_filter.jpg';
import image7 from '../../assets/images/secure_users.jpg';
import image8 from '../../assets/images/parental_control.jpg';

const Page = () => {
    return (
        <div className="container mx-auto px-4 md:px-8">
            <SectionComponent
                title="Get real-time protection against advanced zero-day attack campaigns"
                description="Realize the power of AI-based threat intelligence. Stop zero-day malware and phishing attacks in real-time. Detect and block new Botnets in an instant."
                imageSrc={image1}
                reverse={false}
            />

            <SectionComponent
                title="Leverage Deep Content Inspection"
                description="Prevent evasive threats that would bypass IP, port, and DNS-based filtering. Protect your network with advanced filtering techniques."
                imageSrc={image2}
                reverse={true}
            />

            <SectionComponent
                title="Prevent evasive threats with TLS Inspection"
                description="Unlike superficial filtering tools (e.g. dns filtering), SecGuard is equipped with a powerful Layer7 Enterprise-grade content filter technology allowing you to employ sophisticated security mechanisms capable of detecting and blocking malware and threats which utilize advanced evasion techniques.Stop bad guys from abusing encryption. Enable certificate-based or Full (*) TLS inspection to protect against encrypted threats."
                imageSrc={image3}
                reverse={false}
            />

            <SectionComponent
                title="Always be in-the-know with Industry's best reporting"
                description="Utilize industry’s best network reporting. Be on top of everything that’s going on your network. Create scheduled reports that deliver a clear picture of what’s happening across your networks. Delegate reporting on a need-to-know basis."
                imageSrc={image4}
                reverse={true} >
                <ul className="list-disc pl-6 text-lg">
                    <li>Visualize your network in real-time</li>
                    <li>Start from the big picture; drill down to per-connection details</li>
                    <li>Spot anomalies visually as they occur</li>
                    <li>Always stay up to date with daily/weekly security reports</li>
                </ul>
            </SectionComponent>

            <SectionComponent
                title="Improve Business Productivity with Application Control"
                description="Block and control unauthorized or misbehaving applications with Application Control. SecGuard's rich application database identifies thousands of applications regardless of port numbers."
                imageSrc={image5}
                reverse={false}
            />

            <SectionComponent
                title="Easily take control of your network with Policy based Web Filtering"
                description="Apply Security policies for more than 300 Million web sites under 60 different categories. Create custom categories to blacklist or whitelist sites."
                imageSrc={image6}
                reverse={true}
            />

            <SectionComponent
                title="Secure users, not IP addresses"
                description="Forget about trying to manage IP addresses. Easily enforce policies for individual users and groups to enable granular access controls. Integrate with popular User directory databases like Microsoft Active Directory or LDAP."
                imageSrc={image7}
                reverse={false}
            />

            <SectionComponent
                title="Block harmful content with Parental Controls"
                description="If you're a homelab user, set the rules for for your kids' screen activity."
                imageSrc={image8}
                reverse={true} >
                <ul className="list-disc pl-6 text-lg">
                    <li>You can block sites like YouTube, TikTok, HouseParty (and many more) across all your family’s connected devices.</li>
                    <li>Schedule offline time in advance so you can enjoy family time, make time for homework or let kids finish their chores distraction-free.</li>
                </ul>
            </SectionComponent>

            <SectionComponent
                title="Seamlessly integrate with your existing security tools"
                description="SecGuard seamlessly integrates to your firewall and runs smoothly with other security tools. In the meantime, further integrate with Microsoft Active Directory, Elasticsearch, Splunk, Syslog, etc, to boost your enterprise detection context."
                imageSrc={image1}
                reverse={false}
            />

            <SectionComponent
                title="Automate for operating at scale!"
                description="With RESTful API, create automations to manage and operate at scale."
                imageSrc={image2}
                reverse={true}
            />
        </div>
    );
};

export default Page;
