import React, { useState, useEffect } from 'react';
import Image1 from '../assets/images/ai-bg.jpg';
import Image2 from '../assets/images/Hero_1.png';
import Image3 from '../assets/images/Hero_2.jpg';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HeroSection.css';


const HeroSection = () => {

    const [selectedImage, setselectedImage] = useState(1);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    useEffect(() => {
        AOS.refresh();
    }, [selectedImage]);

    // Drone content data
    const sections = [
        {
            id: 1,
            title: 'SecGuard: Next-Gen Firewall',
            description:
                'Protect your network with SecGuard, the advanced firewall solution offering deep packet inspection, intrusion prevention, and enhanced threat intelligence. Ensure robust security and seamless performance for your businesss.',
            image: Image1,
        },
        {
            id: 2,
            title: 'Comprehensive Network Security',
            description:
                'SecGuard provides unparalleled protection with features like real-time threat detection, automated response, and seamless integration with existing security protocols. Safeguard your digital assets with our cutting-edge firewall technology.',
            image: Image2,
        },
        {
            id: 3,
            title: 'User Friendly and Ideal Choice',
            description:
                'SecGuard provides unparalleled protection with features like real-time threat detection, automated response, and seamless integration with existing security protocols.',
            image: Image3,
        },
    ];

    // Handler to change the selected Section
    const handleSectionSelection = (sectionId) => {
        setFade(true);
        setTimeout(() => {
            setselectedImage(sectionId); // Change the content after fade-out
            setFade(false); // Start the fade-in animation
        }, 500);
    };

    const currentSection = sections.find((section) => section.id === selectedImage);

    return (
        <div className="relative w-full h-screen flex items-center">
            {/* Full-width Image */}
            <img src={currentSection.image} alt={currentSection.title} className={`absolute inset-0 w-full h-full object-cover ${fade ? 'fade-out' : 'fade-in'}`} />

            {/* Overlay Content */}
            <div className={`relative z-10 w-full max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between px-8 ${fade ? 'slide-out-right' : 'slide-in-right'}`}>
                <div className="md:w-1/2 text-white">
                    <h1 className="text-5xl font-bold mb-4">{currentSection.title}</h1>
                    <p className="text-lg mb-6 max-w-lg">{currentSection.description}</p>
                    <button className="px-8 py-3 bg-white text-black font-bold rounded-lg hover:bg-gray-200 transition duration-300">
                        <Link to="/secguard">LEARN MORE</Link>

                    </button>
                </div>
            </div>

            {/* Thumbnails for selection */}
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-4">
                {sections.map((section) => (
                    <button
                        key={section.id}
                        className={`border-4 rounded ${section.id === selectedImage ? 'border-blue-500' : 'border-transparent'}`}
                        onClick={() => handleSectionSelection(section.id)}
                    >
                        <img
                            src={section.image}
                            alt={`Thumbnail ${section.id}`}
                            className="h-16 w-16 object-cover"
                        />
                    </button>
                ))}
            </div>
        </div>
    );
};


export default HeroSection;
