import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
// import './App.css';
import ScrollToTop from './components/ScrollToTop';
import HomePage from "./components/HomePage";
import SecGuard from "./components/SecGuard";
import Product1 from "./components/Product1";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import Contact from './components/ContactSection';

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }


  return (

    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/secguard" element={<SecGuard />} />
        <Route path="/product" element={<Product1 />} />
        {/* <Route path="/drones" element={<Drones />} />
        <Route path="/blog" element={<Blog />} /> */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>


  )
}


