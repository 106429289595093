import React from "react";
import styled from "styled-components";
// import backgroundImg from "../../assets/images/Hero_2.jpg";
import backgroundImg from "../../assets/images/b3.png";

const PageTitleAreaWrapper = styled.section`
  position: relative;
  padding: 100px 0;
  background-color: #000219;
  background-image: url(${backgroundImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  z-index: 1;
  overflow: hidden;
 
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`;

const Title = styled.h1`
  color: white;
  font-size: 48px;
  margin-bottom: 10px;
`;

const Breadcrumb = styled.div`
  display: inline-block;
  margin-top: 20px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 8px 15px;
  border-radius: 5px;
 
  i {
    margin-right: 5px;
  }
 
  span {
    color: #fff;
  }
 
  .arrow {
    margin: 0 5px;
    color: rgba(255, 255, 255, 0.7); /* Lighter arrow color */
  }
`;

const PageHome = styled.a`
  color: #fff;
  text-decoration: none;
`;

const BreadcrumbLink = styled.a`
  color: #57eef1;
  text-decoration: none;
  margin: 0 5px;
  font-weight: bold;
 
  &:hover {
    color: #fff;
  }
`;

const PageTitleArea = ({ title, breadcrumb }) => (
  <PageTitleAreaWrapper>
    <ContentWrapper>
      <Title>{title}</Title>
      <Breadcrumb>
        <span>
          <i className="fas fa-home"></i>
          <PageHome href="/">Home</PageHome>
          <span className="arrow">➔</span>
          <BreadcrumbLink href="/product">{breadcrumb}</BreadcrumbLink>
        </span>
      </Breadcrumb>
    </ContentWrapper>
  </PageTitleAreaWrapper>
);

export default PageTitleArea;
