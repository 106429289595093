import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const WhiteBackgroundSection = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="bg-white py-16">
            <div className="container mx-auto text-center px-4 md:px-8 lg:px-16">
                {/* Title */}
                <h2 className="text-4xl font-bold text-gray-800 mb-6" data-aos="zoom-in">
                    SecGuard: The Next-Gen Firewall
                </h2>
                {/* Description */}
                <p className="text-2xl text-gray-600 mb-4" data-aos="flip-up">
                    Fortify your digital fortress with SecGuard, the cutting-edge firewall designed to protect industrial networks. Experience unparalleled security, seamless integration, and robust performance
                </p>
                <p className="text-lg text-gray-700 font-semibold mb-4" data-aos="flip-up">
                    <strong>With SecGuard, your data is not just secure—it’s invincible.</strong>
                </p>
                {/* <p className="text-lg text-gray-600">
                    You can quickly install Zenarmor and start empowering your existing firewall with state of the art next generation security capabilities.
                </p> */}
            </div>
        </div>
    );
};

export default WhiteBackgroundSection;
