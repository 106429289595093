import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CenterMode.css";
import { FaSearchengin, FaSquarePen, FaChartLine, FaBook, FaBrain, FaBuildingUser } from "react-icons/fa6";
import AOS from 'aos';
import 'aos/dist/aos.css';

const CenterMode = () => {

    const data = [
        {
            icon: <FaSearchengin size={42} />,
            title: "Deep Packet Inspection",
            description: "Stop the latest ransomware and Malware AI based deep packet inspection, including next-gen IPS, web protection provided by SecGuard.",
            className: "slide-bg-1",
        },
        {
            icon: <FaSquarePen size={42} />,
            title: "Easy Manage UI",
            description: "Ensuring that the interface has elements that are easy to access, understand, and use to facilitate those actions.",
            className: "slide-bg-2"
        },
        {
            icon: <FaChartLine size={42} />,
            title: "Scalability",
            description: "Product which helps customers with Pay as you grow model.",
            className: "slide-bg-3"
        },
        {
            icon: <FaBook size={42} />,
            title: "Built-in Reporting",
            description: "Free on-box reporting provides flexible and powerful tools to view network activity and threats across your entire network.",
            className: "slide-bg-4"
        },
        {
            icon: <FaBrain size={42} />,
            title: "AI Powered Firewall",
            description: "Provide industry-grade security and performance.",
            className: "slide-bg-5"
        },
        {
            icon: <FaBuildingUser size={42} />,
            title: "WFH",
            description: "Easily provide your remote workers with secure access to your corporate network from Linux, Mac, and Windows devices.",
            className: "slide-bg-6"
        }
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };
    return (
        <div className="p-12">
            <h2 className="text-black text-4xl text-center mb-8 font-bold">Benefits</h2>
            <div className="slider-container mx-auto max-w-6xl" data-aos="zoom-out">
                <Slider {...settings}>
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`relative slide-item p-6 text-white rounded-lg m-4 flex flex-col justify-center items-center ${item.className}`}
                        >
                            {/* Overlay */}
                            <div className="absolute inset-0 bg-black opacity-70 rounded-lg"></div>

                            {/* Content */}
                            <div className="relative z-10 flex justify-center items-center mb-4">
                                {item.icon}
                            </div>
                            <h3 className="relative z-10 text-2xl font-bold mb-4 text-center">{item.title}</h3>
                            <p className="relative z-10 text-md text-center">
                                {item.description}
                            </p>
                        </div>
                    ))}
                </Slider>
            </div >
        </div >
    );
}

export default CenterMode;