import React, { useState, useEffect, useRef } from 'react';
import image1 from "../../assets/images/Hero_3.png";
import image2 from "../../assets/images/Hero_2.png";
import styled from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProductPage = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const [selectedProduct, setSelectedProduct] = useState('40-SG');
    const [expandedSection, setExpandedSection] = useState(null);

    const productData = {
        '40-SG': {
            image: `${image1}`,
            features: {
                core: {
                    "GE RJ45 Ports": "1xLAN + 3xLAN / WAN Combo",
                    "GE SFP Slots": "-",
                    "10 GE SFP+Slots": "-",
                    "USB Port": 2,
                    "Console Port": 1,
                    "Internal Storage": "64 GB SSD",
                    "Users Supported": 100,
                    "Power": "DC 12V",
                    "Environment": "Operating: -60%, Storage: -20% ~ 75%; 0% - 95% relative humidity, non-condensing",
                },
                systemPerformance: {
                    Enterprise_Traffic_Mix: {
                        "IPS Throughput": "350 Mbps",
                        "NGFW Througput": "250 Mbps",
                        "Threat Protection Throughput": "200 Mbps",
                    },
                    System_Performance_And_Capacity: {
                        "IPv4 Firewall ThroughPut": "2.5 Gbps",
                        "Firewall Throughput (Packets Per Second)": "3 Mpps",
                        "Concurrent Sessions (TCP)": "1,000,000",
                        "New Sessions/Second (TCP)": "10,000",
                        "Firewall Policies": "Unlimited"
                    },
                    VPN_Performance: {
                        "IPsec VPN Throughput(512 byte)": "200 Mbps",
                        "Gateway-to-Gateway IPsec VPN Tunnels": "Unlimited (configuration dependent)",
                        "SSL-VPN Throughput": "150 Mbps",
                        "HIGH AVAILABILITY": "Yes"
                    },
                }
            }
        },
        '100-SG': {
            image: `${image2}`,
            features: {
                core: {
                    "GE RJ45 Ports": "2.5GE X 6",
                    "GE SFP Slots": "-",
                    "10 GE SFP+Slots": "-",
                    "USB Port": 2,
                    "Console Port": 1,
                    "Internal Storage": "128 GB NVMe SSD",
                    "Users Supported": 100,
                    "Power": "DC 12V",
                    "Environment": "Operating: -60%, Storage: -20% ~ 75%; 0% - 95% relative humidity, non-condensing",
                },
                systemPerformance: {
                    Enterprise_Traffic_Mix: {
                        "IPS Throughput": "1.5 Gbps",
                        "NGFW Througput": "900 Mbps",
                        "Threat Protection Throughput": "500 Mbps",
                    },
                    System_Performance_And_Capacity: {
                        "IPv4 Firewall ThroughPut": "5 Gbps",
                        "Firewall Throughput (Packets Per Second)": "3 Mpps",
                        "Concurrent Sessions (TCP)": "1.5M",
                        "New Sessions/Second (TCP)": "45,000",
                        "Firewall Policies": "Unlimited"
                    },
                    VPN_Performance: {
                        "IPsec VPN Throughput(512 byte)": "1.5 Gbps",
                        "Gateway-to-Gateway IPsec VPN Tunnels": "Unlimited (configuration dependent)",
                        "SSL-VPN Throughput": "900 Mbps",
                        "HIGH AVAILABILITY": "Yes"
                    }
                }
            }
        },
        '200-SG': {
            image: `${image1}`,
            features: {
                core: {
                    "GE RJ45 Ports": "8",
                    "GE SFP Slots": "-",
                    "10 GE SFP+Slots": "4",
                    "USB Port": 2,
                    "Console Port": 1,
                    "Internal Storage": "128 GB NVMe SSD",
                    "Users Supported": 250,
                    "Power": "DC 12V",
                    "Environment": "Operating: -60%, Storage: -20% ~ 75%; 0% - 95% relative humidity, non-condensing",
                },
                systemPerformance: {
                    Enterprise_Traffic_Mix: {
                        "IPS Throughput": "2 Gbps",
                        "NGFW Througput": "1.4 Gbps",
                        "Threat Protection Throughput": "900 Mbps",
                    },
                    System_Performance_And_Capacity: {
                        "IPv4 Firewall ThroughPut": "10 Gbps",
                        "Firewall Throughput (Packets Per Second)": "7 Mpps",
                        "Concurrent Sessions (TCP)": "5.7M",
                        "New Sessions/Second (TCP)": "65,000",
                        "Firewall Policies": "Unlimited"
                    },
                    VPN_Performance: {
                        "IPsec VPN Throughput(512 byte)": "2 Gbps",
                        "Gateway-to-Gateway IPsec VPN Tunnels": "Unlimited (configuration dependent)",
                        "SSL-VPN Throughput": "1 Gbps",
                        "HIGH AVAILABILITY": "Yes"
                    }
                }
            }
        },
        '500-SG': {
            image: `${image1}`,
            features: {
                core: {
                    "GE RJ45 Ports": "8",
                    "GE SFP Slots": "-",
                    "10 GE SFP+Slots": "4",
                    "USB Port": 2,
                    "Console Port": 1,
                    "Internal Storage": "256 GB NVMe SSD",
                    "Users Supported": 500,
                    "Power": "DC 12V",
                    "Environment": "Operating: -60%, Storage: -20% ~ 75%; 0% - 95% relative humidity, non-condensing",
                },
                systemPerformance: {
                    Enterprise_Traffic_Mix: {
                        "IPS Throughput": "5 Gbps",
                        "NGFW Througput": "3.5 Gbps",
                        "Threat Protection Throughput": "2 Gbps",
                    },
                    System_Performance_And_Capacity: {
                        "IPv4 Firewall ThroughPut": "20 Gbps",
                        "Firewall Throughput (Packets Per Second)": "9 Mpps",
                        "Concurrent Sessions (TCP)": "7.2M",
                        "New Sessions/Second (TCP)": "95,000",
                        "Firewall Policies": "Unlimited"
                    },
                    VPN_Performance: {
                        "IPsec VPN Throughput(512 byte)": "3 Gbps",
                        "Gateway-to-Gateway IPsec VPN Tunnels": "Unlimited (configuration dependent)",
                        "SSL-VPN Throughput": "1.5 Gbps",
                        "HIGH AVAILABILITY": "Yes"
                    }
                }
            }
        },
        '1000-SG': {
            image: `${image1}`,
            features: {
                core: {
                    "GE RJ45 Ports": "8",
                    "GE SFP Slots": "-",
                    "10 GE SFP+Slots": "4",
                    "USB Port": 2,
                    "Console Port": 1,
                    "Internal Storage": "256 GB NVMe SSD",
                    "Users Supported": 1000,
                    "Power": "DC 12V",
                    "Environment": "Operating: -60%, Storage: -20% ~ 75%; 0% - 95% relative humidity, non-condensing",
                },
                systemPerformance: {
                    Enterprise_Traffic_Mix: {
                        "IPS Throughput": "9 Gbps",
                        "NGFW Througput": "5 Gbps",
                        "Threat Protection Throughput": "3 Mbps",
                    },
                    System_Performance_And_Capacity: {
                        "IPv4 Firewall ThroughPut": "36 Gbps",
                        "Firewall Throughput (Packets Per Second)": "12 Mpps",
                        "Concurrent Sessions (TCP)": "10M",
                        "New Sessions/Second (TCP)": "1,25,000",
                        "Firewall Policies": "Unlimited"
                    },
                    VPN_Performance: {
                        "IPsec VPN Throughput(512 byte)": "7 Gbps",
                        "Gateway-to-Gateway IPsec VPN Tunnels": "Unlimited (configuration dependent",
                        "SSL-VPN Throughput": "2.1 Gbps",
                        "HIGH AVAILABILITY": "Yes"
                    }
                }
            }
        },
        '3000-SG': {
            image: `${image1}`,
            features: {
                core: {
                    "GE RJ45 Ports": "8",
                    "GE SFP Slots": "-",
                    "10 GE SFP+Slots": "4",
                    "USB Port": 2,
                    "Console Port": 1,
                    "Internal Storage": "256 GB NVMe SSD",
                    "Users Supported": 3000,
                    "Power": "DC 12V",
                    "Environment": "Operating: -60%, Storage: -20% ~ 75%; 0% - 95% relative humidity, non-condensing",
                },
                systemPerformance: {
                    Enterprise_Traffic_Mix: {
                        "IPS Throughput": "12 Gbps",
                        "NGFW Througput": "9 Gbps",
                        "Threat Protection Throughput": "6.3 Mbps",
                    },
                    System_Performance_And_Capacity: {
                        "IPv4 Firewall ThroughPut": "73 Gbps",
                        "Firewall Throughput (Packets Per Second)": "20 Mpps",
                        "Concurrent Sessions (TCP)": "22M",
                        "New Sessions/Second (TCP)": "2.20,000",
                        "Firewall Policies": "Unlimited"
                    },
                    VPN_Performance: {
                        "IPsec VPN Throughput(512 byte)": "12 Gbps",
                        "Gateway-to-Gateway IPsec VPN Tunnels": "Unlimited (configuration dependent)",
                        "SSL-VPN Throughput": "2.6 Gbps",
                        "HIGH AVAILABILITY": "Yes"
                    }
                }
            }
        },
        // ... data for other products
    };

    const handleTabClick = (productId) => {
        setSelectedProduct(productId);
    };

    const handleSectionClick = (sectionId) => {
        setExpandedSection(sectionId === expandedSection ? null : sectionId);
    };

    return (
        <StyledProductPage>
            <StyledTitle>Product Details</StyledTitle>
            <StyledDescription data-aos="fade-right">
                <p>Our firewalls are meticulously engineered to deliver exceptional network security and performance for both enterprise and industrial environments. Each model integrates advanced features such as high-speed intrusion prevention systems (IPS) and next generation firewall (NGFW) throughput, complemented by robust VPN support and comprehensive SSL inspection. Balancing compactness with power, these firewalls ensure continuous, reliable operation and sophisticated threat detection tailored to complex network settings.</p><br />
                <p>Optimized for high-performance network management, these firewalls excel in advanced firewall capabilities and precise routing functions across a broad range of
                    applications. They provide strong protective measures and versatile deployment options, making them essential tools for managing intricate networks and ensuring dependable security in various scenarios.</p> <br />
                <p>
                    Equipped with user-friendly management interfaces and scalable designs, the firewalls
                    adapt effectively to evolving network requirements and emerging threats. They balance
                    rigorous security with operational efficiency, positioning the firewalls as ideal choices for
                    safeguarding complex network infrastructures while maintaining ease of use and
                    management.
                </p></StyledDescription>
            <StyledProductDetails>
                <StyledHeading>MODELS</StyledHeading>

                <StyledTabs >

                    <StyledTab
                        className={selectedProduct === '40-SG' ? 'active' : ''}
                        onClick={() => handleTabClick('40-SG')}
                    >
                        40-SG
                    </StyledTab>
                    <StyledTab
                        className={selectedProduct === '100-SG' ? 'active' : ''}
                        onClick={() => handleTabClick('100-SG')}
                    >
                        100-SG
                    </StyledTab>
                    <StyledTab
                        className={selectedProduct === '200-SG' ? 'active' : ''}
                        onClick={() => handleTabClick('200-SG')}
                    >
                        200-SG
                    </StyledTab>
                    <StyledTab
                        className={selectedProduct === '500-SG' ? 'active' : ''}
                        onClick={() => handleTabClick('500-SG')}
                    >
                        500-SG
                    </StyledTab>
                    <StyledTab
                        className={selectedProduct === '1000-SG' ? 'active' : ''}
                        onClick={() => handleTabClick('1000-SG')}
                    >
                        1000-SG
                    </StyledTab>
                    <StyledTab
                        className={selectedProduct === '3000-SG' ? 'active' : ''}
                        onClick={() => handleTabClick('3000-SG')}
                    >
                        3000-SG
                    </StyledTab>
                    {/* ... other tabs */}
                </StyledTabs>
                {/* <StyledTitleContainer>
                    <StyledImage src={productData[selectedProduct].image} alt={selectedProduct} />
                    <StyledImageDetail>Product Image Detail</StyledImageDetail>
                </StyledTitleContainer> */}
                <StyledFeaturesTable data-aos="zoom-out" data-aos-delay="500">
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: '#0c074a', color: 'white' }}>Feature</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(productData[selectedProduct].features.core).map(([feature, value]) => (
                            <tr key={feature}>
                                <td>{feature}</td>
                                <td>{value}</td>
                            </tr>
                        ))}
                        {Object.entries(productData[selectedProduct].features.systemPerformance).map(([sectionId, sectionData]) => (
                            <React.Fragment key={sectionId}>
                                <tr>
                                    <td colSpan={2} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSectionClick(sectionId)}>
                                        <span style={{ fontWeight: 'bold' }}>{sectionId}</span>
                                        <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                                            {expandedSection === sectionId ? '-' : '+'}
                                        </span>
                                    </td>
                                </tr>
                                {expandedSection === sectionId && (
                                    Object.entries(sectionData).map(([feature, value]) => (
                                        <tr key={feature}>
                                            <td>{feature}</td>
                                            <td>{value}</td>
                                        </tr>
                                    ))
                                )}
                            </React.Fragment>
                        ))}

                    </tbody>
                </StyledFeaturesTable>
                <StyledAdditionalContent>
                    <p>Copper SFP module is not supported.</p>
                    <p>Note: All performance values are "up to" and vary depending on system configuration.</p><br />
                    <p>1.  IPsec VPN performance test uses AES256-SHA256.</p>
                    <p>2.  IPS (Enterprise Mix), Application Control, NGFW and Threat Protection are measured with Logging enabled.</p>
                    <p>3.  SSL Inspection performance values use an average of HTTPS sessions of different cipher suites.</p>
                    <p>4.  NGFW performance is measured with Firewall, IPS and Application Control enabled.</p>
                    <p>5.  Threat Protection performance is measured with Firewall, IPS, Application Control and Malware.</p>
                </StyledAdditionalContent>
            </StyledProductDetails>
        </StyledProductPage>
    );
};

export default ProductPage;

// Styled components
const StyledProductPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledTab = styled.button`
  padding: 10px 20px !important;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;

  &.active {
    background-color: #666769;
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;



const StyledImage = styled.img`
  width: 600px; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  margin-right: 20px;
`;

const StyledImageDetail = styled.div`
  font-size: 14px;
  color: #666;
`;

const StyledProductDetails = styled.div`
  text-align: center;
`;

const StyledFeaturesTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto; /* Center the table */

  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #0c074a;
    color: white;
  }

  td:first-child {
    background-color: #358a11; /* Green */
    color: white;
  }

`;

const StyledAdditionalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  font-size: 0.8em;
  color: #666;
`;

const StyledTitle = styled.h1`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 30px;
`;

const StyledDescription = styled.p`
  text-align: left;
  font-size: 18px;
  margin-bottom: 40px;
  padding: 0 30px;
`;

const StyledHeading = styled.h2`
  text-align: left !important;
  font-weight: bold !important;
  font-size: 20px !important;
  margin-top: 5px;
  justify-content: center !important;
  text-align: center !important;
`;

