import Section1 from "./SecGuard/Section1";
import Section2 from "./SecGuard/Section2";
import Section3 from "./SecGuard/Section3_PageImpl";
import Section4 from "./SecGuard/Section4";

const SecGuard = () => {
    return (
        <>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    )
}

export default SecGuard;