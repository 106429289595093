import HeroSection from './HeroSection';
import FeatureSection from './FeatureSection';
import ProductSection from './ProductSection';
import InfoSection from './InfoSection';
import Footer from './Footer';
import ContactForm from './ContactSection';
import CenterMode from './CenterMode';

const HomePage = () => {
    return (
        <>
            <div className="pt-16">
                <HeroSection />
                <FeatureSection />
                <ProductSection />
                <InfoSection />
            </div>
            <CenterMode />
            <ContactForm />
        </>
    )
}

export default HomePage;